export function AuthError(e) {
  this.message = e.message;
  this.code = e.code;
}

export function InvalidAccessError(message) {
  this.message = message;
}

export function NoAccessError(message) {
  this.message = message;
}

export const firebaseErrors = {
  'auth/user-not-found': 'Usuário inexistente ou inválido.',
  'auth/wrong-password': 'Usuário inexistente ou senha inválida.',
};
