import React from 'react';

const ProfileRounded = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M12.9997 0C5.8202 0 0 5.8206 0 12.9997 0 20.1792 5.8202 26 12.9997 26 20.179 26 26 20.1792 26 12.9997 26 5.8203 20.179 0 12.9997 0z"
        />
        <path
          fill="#FFF"
          d="M6.6 8h11.8c.8837 0 1.6.7163 1.6 1.6v6.8c0 .8837-.7163 1.6-1.6 1.6H6.6c-.8837 0-1.6-.7163-1.6-1.6V9.6C5 8.7163 5.7163 8 6.6 8zm2.3872 8.3256c2.2854 0 2.4426-.8295 2.4426-.8295 0-1.6296-1.4953-1.6565-1.8719-2.0688l-.0447-.2246c.4758-.2337.8102-.7847.8102-1.4277 0-.8544-.5906-1.547-1.319-1.547-.7282 0-1.3188.6926-1.3188 1.547 0 .6485.3404 1.203.8227 1.433l-.0393.1976c-.3433.4334-1.89.433-1.89 2.0904 0 0 .1229.8296 2.4082.8296zm4.2922-5.2487a.3846.3846 0 100 .7693h4.757a.3846.3846 0 100-.7693h-4.757zm0 1.5385a.3846.3846 0 100 .7692h4.757a.3846.3846 0 100-.7692h-4.757zm0 1.5384a.3846.3846 0 000 .7693h4.757a.3846.3846 0 100-.7693h-4.757z"
        />
      </g>
    </svg>
  );
};

export default ProfileRounded;
