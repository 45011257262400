import React from 'react';

const ProfileRounded = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M12.9997 0C20.179 0 26 5.8203 26 12.9997 26 20.1792 20.179 26 12.9997 26 5.8202 26 0 20.1792 0 12.9997 0 5.8207 5.8202 0 12.9997 0zm5.0645 12.5327c-.1792-.1758-.3966-.2637-.6526-.2637h-.3068v-1.8088c0-1.1555-.422-2.1477-1.2664-2.9767-.8444-.829-1.8548-1.2435-3.032-1.2435-1.177 0-2.1877.4145-3.032 1.2435-.8443.829-1.2663 1.8211-1.2663 2.9767v1.8088h-.307c-.2558 0-.4733.0879-.6525.2637-.179.1757-.2686.3893-.2686.6407v5.426c0 .251.0896.4647.2686.6406.1792.1757.3967.2637.6525.2637h9.2108c.256 0 .4733-.088.6525-.2637.179-.1759.2686-.3895.2686-.6407v-5.426c.0002-.2512-.0896-.4647-.2688-.6406z" />
        <path d="M15.3318 12.5008h-4.9125V10.692c0-.6657.24-1.234.7197-1.705.4798-.471 1.0586-.7064 1.7366-.7064.6782 0 1.2569.2354 1.7367.7064.4796.4709.7195 1.0393.7195 1.705v1.8088z" />
      </g>
    </svg>
  );
};

export default ProfileRounded;
