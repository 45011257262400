import React from 'react';

import LoginPage from '../components/LoginPage';
import GlobalCss from '../components/GlobalCss';
import FirebaseProvider from '../components/FirebaseProvider';

const Login = (props) => {
  return (
    <FirebaseProvider>
      <GlobalCss>
        <LoginPage {...props} />
      </GlobalCss>
    </FirebaseProvider>
  );
};

export default Login;
