import React, { useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { css, keyframes } from '@emotion/core';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/styles';
import facepaint from 'facepaint';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import compose from 'lodash/fp/compose';
import 'typeface-roboto';
// import { stringify } from 'qs';

import { withFirebase } from './Firebase';
import FormikField from './FormikInput';
import Logo from '../assets/eyemobile_white.inline.svg';
import { AuthError, InvalidAccessError, firebaseErrors } from '../utils/errors';
import PasswordRounded from './images/PasswordRounded';
import ProfileRounded from './images/ProfileRounded';
import GoogleIcon from '../assets/google-icon.png';

const mq = facepaint(['@media screen and (min-width: 360px)']);

const shake = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(-20px);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
`;

const fadeOutTop = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
`;

const inputField = css`
  font-size: 14px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #a9a9a9;
  color: #737689;
  height: 35px;
  line-height: 1;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 8px;
`;

const shakeAnimation = css`
  animation: ${shake} 0.5s ease 0s 1;
`;

const fadeOutTopAnimation = css`
  animation: ${fadeOutTop} 0.8s ease 0s 1;
`;

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});

const pageStyles = () => ({
  button: {
    backgroundColor: '#7d1dd1',
    borderRadius: 30,
    color: '#FAFAFA',
    padding: '14px 0',
    lineHeight: 1,
    fontFamily: 'Avenir, Arial, sans-serif',
    '&:hover': {
      backgroundColor: '#7d1dd1',
      color: '#FAFAFA',
    },
    '&:focus': {
      color: '#FAFAFA',
    },
    '&:disabled': {
      backgroundColor: '#dddddd',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  gButton: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: '"Roboto", Arial, sans-serif',
    fontWeight: 500,
    padding: 8,
    borderRadius: 5,
    fontSize: 14,
    height: 40,
    width: 'auto',
    alignSelf: 'center',
    border: 'thin solid #F0F0F0',
    boxShadow: '1px 1px 0px 1px rgba(0,0,0,0.05)',
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: '1px 4px 5px 1px rgba(0,0,0,0.1)',
      backgroundColor: 'white',
    },
  },
  progressBar: {
    backgroundColor: '#999',
  },
  progressRoot: {
    backgroundColor: 'transparent',
  },
});

const boxWrapper = css(
  mq({
    width: '100%',
    padding: ['32px 16px', '45px 62px'],
    maxWidth: 403,
    position: 'relative',
  }),
);

const LoginPage = ({ classes, location, firebase }) => {
  const loadingTimer = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShake, setShouldShake] = useState(false);
  const [shouldFadeTop, setShouldFadeTop] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const progress = () => {
    setCompleted((oldCompleted) => {
      const remain = 100 - oldCompleted;
      const distance = Math.floor(remain / 8);
      const max = oldCompleted + distance;
      return Math.floor(
        Math.random() * (max - oldCompleted + 1) + oldCompleted,
      );
    });
  };

  const startProgress = () => {
    clearInterval(loadingTimer);
    setCompleted(0);
    setIsLoading(true);
    const intervalId = setInterval(progress, 500);
    loadingTimer.current = intervalId;
  };

  const finishProgress = () => {
    clearInterval(loadingTimer.current);
    setCompleted(100);
    setIsLoading(false);
  };

  const signInWithGoogle = async () => {
    setShouldShake(false);
    setShouldFadeTop(false);
    setHasError(false);

    try {
      const socialAuthUser = await firebase
        .doSignInWithGoogle();

      if (!/eyemobile.com.br/.test(socialAuthUser.user.email)) {
        throw new AuthError({ message: 'Usuário rejeitado.' });
      }

      firebase
        .user(socialAuthUser.user.uid)
        .set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });

      navigate('/');
    } catch (e) {
      setHasError(true);
      setErrorMsg(firebaseErrors[e.code] || e.message);
      setShouldShake(true);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'black',
        padding: '0 16px',
        flexDirection: 'column',
      }}
    >
      <Logo
        css={shouldFadeTop && fadeOutTopAnimation}
        style={{
          marginBottom: '52.6px',
          display: 'block',
          width: 210,
          height: 30,
        }}
      />
      <Paper
        css={[
          boxWrapper,
          shouldShake && shakeAnimation,
          shouldFadeTop && fadeOutTopAnimation,
        ]}
        elevation={6}
        style={{ borderRadius: 15 }}
      >
        <LinearProgress
          variant="determinate"
          value={completed}
          style={{
            position: 'absolute',
            top: 0,
            left: 6,
            width: 'calc(100% - 6px)',
            opacity: isLoading ? 1 : 0,
            transition: 'opacity 0.2s',
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
          classes={{
            root: classes.progressRoot,
            bar: classes.progressBar,
          }}
        />
        {location.state && location.state.errorMsg && (
          <p
            style={{
              textAlign: 'center',
              color: 'rgb(255, 79, 101)',
            }}
          >
            {location.state.errorMsg}
          </p>
        )}
        {hasError && (
          <p
            style={{
              textAlign: 'center',
              color: 'rgb(255, 79, 101)',
            }}
          >
            {errorMsg}
          </p>
        )}
        <Formik
          validationSchema={loginSchema}
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={async (values) => {
            try {
              setShouldShake(false);
              setShouldFadeTop(false);
              setHasError(false);
              startProgress();

              if (!/eyemobile.com.br/.test(values.username)) {
                throw new AuthError({ message: 'Usuário rejeitado.' });
              }

              await firebase
                .doSignInWithEmailAndPassword(values.username, values.password)
                .catch((e) => Promise.reject(new AuthError(e)));

              finishProgress();
              setShouldFadeTop(true);

              setTimeout(() => {
                if (location.state && location.state.redirectTo) {
                  window.location.assign(location.state.redirectTo);
                } else {
                  navigate('/introduction');
                }
              }, 700);
            } catch (e) {
              finishProgress();
              if (e instanceof AuthError) {
                setHasError(true);
              }
              if (!(e instanceof InvalidAccessError)) {
                setErrorMsg(firebaseErrors[e.code] || e.message);
                setShouldShake(true);
              }
            }
          }}
        >
          {({ handleSubmit, isValid }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  marginBottom: 40,
                }}
              >
                <Field
                  name="username"
                  component={FormikField}
                  placeholder="Email"
                  css={inputField}
                  hideIcons
                  errorStyle={{
                    position: 'absolute',
                    top: '100%',
                    right: 0,
                  }}
                  inputIcon={
                    <ProfileRounded
                      style={{
                        flexShrink: 0,
                        color: '#7D1DD1',
                        marginRight: 8,
                      }}
                    />
                  }
                />
              </div>
              <div
                style={{
                  position: 'relative',
                  marginBottom: 30,
                }}
              >
                <Field
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  component={FormikField}
                  placeholder="Senha"
                  css={inputField}
                  hideIcons
                  errorStyle={{
                    position: 'absolute',
                    top: '100%',
                    right: 0,
                  }}
                  inputIcon={
                    <PasswordRounded
                      style={{
                        flexShrink: 0,
                        color: '#7D1DD1',
                        marginRight: 8,
                      }}
                    />
                  }
                />
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{
                    position: 'absolute',
                    top: -4,
                    right: 10,
                  }}
                >
                  {showPassword ? (
                    <VisibilityOff style={{ fontSize: 20 }} />
                  ) : (
                    <Visibility style={{ fontSize: 20 }} />
                  )}
                </IconButton>
              </div>
              <Button
                classes={{
                  root: classes.button,
                }}
                type="submit"
                disabled={isLoading || !isValid}
              >
                Acessar
              </Button>
              <Button
                classes={{
                  root: classes.gButton,
                }}
                style={{
                  marginTop: 32,
                }}
                type="button"
                onClick={signInWithGoogle}
              >
                <img src={GoogleIcon} style={{ marginRight: 24, width: 18, height: 18, display: 'block' }} />
                Entrar com o Google
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(withStyles(pageStyles), withFirebase);

export default enhance(LoginPage);
